import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import StartIntro from '../components/Intro/StartIntro'

export default function Index({ userData }) {
    /*useEffect(() => {
		// location.pathname === '/' && (location.pathname = '/dashboard/clickit-dashboards')
	});*/

    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (!sessionStorage.getItem('showModal') && !localStorage.getItem('showIntro')) {
            sessionStorage.setItem('showModal', true)
            setShowModal(true)
            setTimeout(() => {
                setShowModal(false)
            }, 60000)
        }
    }, [])

    return (
        <>
            <Layout title='Dashboard'>
                {/*
					showModal &&
					<StartIntro
						setShowModal={setShowModal}
					></StartIntro>
				*/}
            </Layout>
        </>
    )
}
